// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import Services from 'components/units/unitPages/unit/services';

import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';

// Image

// import MapImage from 'images/mapsImages/cdi-bsb.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const GridMainServices = styled.div`
  margin-bottom: 4.375rem;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 1.25rem 0;
    border-radius: 8px;
  }

  p {
    line-height: 24px !important;
    margin-bottom: 40px !important;
  }

  ul {
    li {
      margin-bottom: 20px;
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottomPart && '1px solid #C6C8CC'};
  ul {
    margin-bottom: 0;
    li {
      :last-child {
        margin-bottom: 2px;
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
    margin-bottom: ${props => props.lastChild && '0'};
    @media only screen and (min-width: 1100px) {
      margin-bottom: ${props => props.lastChild && '3.75rem'};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
`;

const PartDropdown = styled.div`
  grid-column: ${props => props.gridColumn};
  border-top: 1px solid #c6c8cc;
  padding-top: 20px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    padding-top: 4.375rem;
    margin-bottom: 3.75rem;
  }
  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 10;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: initial;
    }
    :first-child {
      h2 {
        margin-top: 0;
      }
    }
    .dropdown {
      width: 100%;
      flex-direction: column;
      @media only screen and (min-width: 768px) {
        width: 49%;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, markdownCards) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / span 12'} isCDIUnits>
      <FeaturedMarketing
        markdown={getMarkdown(page, 'ckvwrqucwkr2t0b66gm1ess4l', true)}
        breadCrumb={getMarkdown(page, 'ckvwrm328kryh0a266ho0y1it', true)}
        asset={getMarkdown(page, 'ckvwrqucwkr2t0b66gm1ess4l', false, true)}
        isCDIBrasilia
        firstBlobTransformDesk="translate(-23%,123%) rotate(8deg)"
        firstBlobTransformDeskLarge="translate(-23%,123%) rotate(8deg)"
        firstBlobTransformLap="translate(-14%,166%) rotate(8deg)"
        firstBlobTransformMob="translate(-13%,132%) rotate(8deg)"
        secBlobTransformDesk="translate(15%,-69%) rotate(-4deg)"
        secBlobTransformDeskLarge="translate(20%, -68%) rotate(-5deg)"
        secBlobTransformLap="translate(15%,-82%) rotate(-4deg)"
        secBlobTransformMob="translate(15%,-14%) rotate(-4deg)"
        containerMarginBottomLap="0"
        containerMarginBottomDesk
        containerMarginBottomTab="0"
        boxInfoParagraphImgTransformDesk="translate(0, 75%)"
        boxInfoParagraphImgWidth="unset"
        boxInfoParagraphImgHeight="unset"
        boxImagesJustifyContentLap="center"
        boxContentPaddingBottomDesk="4.125rem"
        titleWidthDesk="20%"
        titleWidthLap="10%"
        titleWidthTab="0%"
        titleWidthMob="20%"
        titleWidthMob320="20%"
        isMarginTitleAddress="translate(0%, 30%);"
        boxContentHeightDesk="27rem"
        paddingTopTextBoxInfo="-2rem"
        boxInfoPaddingLeftEmDesk="2.063rem"
        boxContentHeightLap="auto"
        boxContentMarginTopDesk="-0.3rem"
        marginTopBoxInfoDesk="-15.6rem"
        marginTopBoxInfoLap="-8.5rem"
      />
    </Part>
    <Part gridColumn={'2 / -2'} notLeft>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckvwrtsu8krzd0b66ix7d5hss', true)}
        fragments={getMarkdown(page, 'ckvwrtsu8krzd0b66ix7d5hss')}
        padding="0 0 4.375rem"
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        contentTagImgTransform="unset"
        withoutBorderTop
        contentImgWidthLaptop="100%"
        notDuplicateUl={'none'}
        noPaddingMobile
        paddingContainer="20px 0 10px"
        listBullets
        spacePioneirismo
      />
    </Part>
    <PartDropdown gridColumn={'2 / -2'}>
      {/* Titulo*/}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckvws6d00kyti0b66b3xdjgrp', true)}
        fragments={getMarkdown(page, 'ckvws6d00kyti0b66b3xdjgrp')}
        padding="0 0 0"
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isFacilities
        contentTagImgTransform="unset"
        withoutBorderTop
        contentImgWidthLaptop="unset"
        noPaddingTop
        contactFlex="flex"
        contentContactCardChild5
        paddingBottom
        tittleMarginBottom
      />

      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23ecawewc90b7255zzhzjm', true)}
        fragments={getMarkdown(page, 'ckw23ecawewc90b7255zzhzjm')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
      {/* Dropdown2 */}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23gpy8eyls0a264pkg6cb0', true)}
        fragments={getMarkdown(page, 'ckw23gpy8eyls0a264pkg6cb0')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
      {/* Dropdown3 */}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23iclcex220b72jqon0bgn', true)}
        fragments={getMarkdown(page, 'ckw23iclcex220b72jqon0bgn')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
      {/* Dropdown4 */}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23k8hseudi0c29lpa72lqs', true)}
        fragments={getMarkdown(page, 'ckw23k8hseudi0c29lpa72lqs')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
      {/* Dropdown5 */}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23makgevux0b6672pr3p2k', true)}
        fragments={getMarkdown(page, 'ckw23makgevux0b6672pr3p2k')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
      {/* Dropdown6 */}
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckw23obvceuy70c293r1u6j5d', true)}
        fragments={getMarkdown(page, 'ckw23obvceuy70c293r1u6j5d')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        containerPaddingDesk="1.875rem 0 0"
        containerPaddingLaptop="0"
        contentFontSizeMob="0.875rem"
        isFragmentTitle
        displayTitle="none"
        cuidados
        paddingLeftListDesk=".8rem"
        paddingRightListDesk="2rem"
        infusion
        isPageDoeSangue
        dropdownMarginBottom
      />
    </PartDropdown>
    <GridMainServices>
      <Part gridColumn={'2 / -2'}>
        <MainServices
          markdown={getMarkdown(page, 'ckvwrw8swkvda0b72om3n83l7', true)}
          infusion
          paddingCentroCirurgico
        ></MainServices>
      </Part>
    </GridMainServices>
    <Part gridColumn={'2 / -2'} notLeft borderBottomPart>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckvwrz8tskwj50c29jeevdrun', true)}
        fragments={getMarkdown(page, 'ckvwrz8tskwj50c29jeevdrun')}
        padding="0 0 4.375rem"
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isFacilities
        contentTagImgTransform="unset"
        withoutBorderTop
        contentImgWidthLaptop="unset"
        noPaddingTop
        contactFlex="flex"
        contentContactCardChild5
        paddingBottom
      />
    </Part>
    <Part gridColumn={'2 / -2'} lastChild>
      <Services
        markdown={markdownCards}
        fragments={getMarkdown(page, 'ckvws3kowkxkc0b723n5f3xrr')}
        cardHowCanIHelp
        isportalPaciente="17.125rem"
        titleDeskWithPortalPaciente="100%"
        titleDeskMaxWithPortalPaciente="210px"
        heightTitleCardPortalPaciente="58px"
        marginBottomCardPortalPaciente="20px"
        widthTextHiperlink="210px"
        marginLeftPortalPaciente="0 !important"
        isCDIUnits
        isActiveCard={true}
        noMarginCardStyle
        isAdjustmentInCssLayoutMobile
        isPortalPaciente
        paddingBottom="20px"
        prevNextButton
        isCentroCirurgico
      />
    </Part>
  </GridContainer>
);

const CCIBrasilia = ({ page, location, specialties }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [markdownCards, setMarkdownCards] = useState('');
  let arrCards = '';

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    arrCards = specialties
      .filter(item => item.centroCirurgico === true && item.unity == 'bsb')
      .map(item => item.markdownCards)
      .join('(end)');
    setMarkdownCards(arrCards);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckvwrqucwkr2t0b66gm1ess4l', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, markdownCards)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckvwrjmbskpeq0b66nbhwtdek" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
            specialties {
              name
              unity
              centroCirurgico
              markdownCards
            }
          }
        }
      `}
      render={response => {
        return (
          <CCIBrasilia
            page={response.gcms.site.pages}
            specialties={response.gcms.specialties}
            location={location}
          />
        );
      }}
    />
  );
};
